export const positiveSVGs = [
    require("./Twemoji_v12/Twemoji12 1f31f.svg"),
    require("./Twemoji_v12/Twemoji12 1f642.svg"),
    require("./Twemoji_v12/Twemoji12 1f920.svg"),
    require("./Twemoji_v12/Twemoji12 1f929.svg"),
    require("./Twemoji_v12/Twemoji12.1.3 1f3f5.svg"),
    require("./Twemoji_v12/Twemoji12 1f0cf.svg"),
    require("./Twemoji_v12/Twemoji12 1f3af.svg"),
    require("./Twemoji_v12/Twemoji12 1f3b7.svg"),
    require("./Twemoji_v12/Twemoji12 1f3b8.svg"),
    require("./Twemoji_v12/Twemoji12 1f4a1.svg"),
    require("./Twemoji_v12/Twemoji12 1f4aa.svg"),
    require("./Twemoji_v12/Twemoji12 1f4ab.svg"),
    require("./Twemoji_v12/Twemoji12 1f9b8-1f3fb-200d-2640-fe0f.svg"),
    require("./Twemoji_v12/Twemoji12 1f9b8-1f3fb-200d-2642-fe0f.svg"),
    require("./Twemoji_v12/Twemoji12 1f9b9-1f3fd-200d-2640-fe0f.svg"),
    require("./Twemoji_v12/Twemoji12 1f9b9-1f3fd-200d-2642-fe0f.svg"),
    require("./Twemoji_v12/Twemoji12 1f9c1.svg"),
    require("./Twemoji_v12/Twemoji12 1f9c6.svg"),
    require("./Twemoji_v12/Twemoji12 1f9de-200d-2640-fe0f.svg"),
    require("./Twemoji_v12/Twemoji12 1f9de-200d-2642-fe0f.svg"),
    require("./Twemoji_v12/Twemoji12 1f9f8.svg"),
    require("./Twemoji_v12/Twemoji12 1f31a.svg"),
    require("./Twemoji_v12/Twemoji12 1f31e.svg"),
    require("./Twemoji_v12/Twemoji12 1f44c.svg"),
    require("./Twemoji_v12/Twemoji12 1f44d.svg"),
    require("./Twemoji_v12/Twemoji12 1f44f.svg"),
    require("./Twemoji_v12/Twemoji12 1f60a.svg"),
    require("./Twemoji_v12/Twemoji12 1f60b.svg"),
    require("./Twemoji_v12/Twemoji12 1f60d.svg"),
    require("./Twemoji_v12/Twemoji12 1f60e.svg"),
    require("./Twemoji_v12/Twemoji12 1f63a.svg"),
    require("./Twemoji_v12/Twemoji12 1f63b.svg"),
    require("./Twemoji_v12/Twemoji12 1f91f.svg"),
    require("./Twemoji_v12/Twemoji12 1f98a.svg"),
    require("./Twemoji_v12/Twemoji12 1f98b.svg"),
    require("./Twemoji_v12/Twemoji12 1f99b.svg"),
    require("./Twemoji_v12/Twemoji12 1f99d.svg"),
    require("./Twemoji_v12/Twemoji12 1f320.svg"),
    require("./Twemoji_v12/Twemoji12 1f416.svg"),
    require("./Twemoji_v12/Twemoji12 1f423.svg"),
    require("./Twemoji_v12/Twemoji12 1f428.svg"),
    require("./Twemoji_v12/Twemoji12 1f430.svg"),
    require("./Twemoji_v12/Twemoji12 1f431.svg"),
    require("./Twemoji_v12/Twemoji12 1f433.svg"),
    require("./Twemoji_v12/Twemoji12 1f466-1f3fb.svg"),
    require("./Twemoji_v12/Twemoji12 1f466-1f3fd.svg"),
    require("./Twemoji_v12/Twemoji12 1f466-1f3ff.svg"),
    require("./Twemoji_v12/Twemoji12 1f467-1f3fb.svg"),
    require("./Twemoji_v12/Twemoji12 1f467-1f3fd.svg"),
    require("./Twemoji_v12/Twemoji12 1f467-1f3ff.svg"),
    require("./Twemoji_v12/Twemoji12 1f600.svg"),
    require("./Twemoji_v12/Twemoji12 1f601.svg"),
    require("./Twemoji_v12/Twemoji12 1f603.svg"),
    require("./Twemoji_v12/Twemoji12 1f604.svg"),
    require("./Twemoji_v12/Twemoji12 1f973.svg"),
];

export const negativeSVGs = [
    require("./Twemoji_v12/Twemoji12 1f633.svg"),
    require("./Twemoji_v12/Twemoji12 1f641.svg"),
    require("./Twemoji_v12/Twemoji12 1f644.svg"),
    require("./Twemoji_v12/Twemoji12 1f974.svg"),
    require("./Twemoji_v12/Twemoji12 1f4a5.svg"),
    require("./Twemoji_v12/Twemoji12 1f6a7.svg"),
    require("./Twemoji_v12/Twemoji12 1f6a8.svg"),
    require("./Twemoji_v12/Twemoji12 1f6ab.svg"),
    require("./Twemoji_v12/Twemoji12 1f6d1.svg"),
    require("./Twemoji_v12/Twemoji12 1f9d0.svg"),
    require("./Twemoji_v12/Twemoji12 1f9e8.svg"),
    require("./Twemoji_v12/Twemoji12 1f32a.svg"),
    require("./Twemoji_v12/Twemoji12 1f44e.svg"),
    require("./Twemoji_v12/Twemoji12 1f47a.svg"),
    require("./Twemoji_v12/Twemoji12 1f47b.svg"),
    require("./Twemoji_v12/Twemoji12 1f61e.svg"),
    require("./Twemoji_v12/Twemoji12 1f61f.svg"),
    require("./Twemoji_v12/Twemoji12 1f62a.svg"),
    require("./Twemoji_v12/Twemoji12 1f62b.svg"),
    require("./Twemoji_v12/Twemoji12 1f62d.svg"),
    require("./Twemoji_v12/Twemoji12 1f62e.svg"),
    require("./Twemoji_v12/Twemoji12 1f63f.svg"),
    require("./Twemoji_v12/Twemoji12 1f64a.svg"),
    require("./Twemoji_v12/Twemoji12 1f440.svg"),
    require("./Twemoji_v12/Twemoji12 1f480.svg"),
    require("./Twemoji_v12/Twemoji12 1f616.svg"),
    require("./Twemoji_v12/Twemoji12 1f629.svg"),
    require("./Twemoji_v12/Twemoji12 1f631.svg"),
    require("./Twemoji_v12/Twemoji12 1f632.svg"),
];

export const timeReminderSVG = require("./Twemoji_v12/Twemoji12 23f0.svg");

export function randomFrom<V>(arrayToChooseFrom: V[]): V {
    return arrayToChooseFrom[Math.floor(Math.random() * arrayToChooseFrom.length - 1) + 1]
}

export const randomPositiveSVG = () => randomFrom(positiveSVGs);
export const randomNegativeSVG = () => randomFrom(negativeSVGs);

export const houseSVG = require("./Zahlzerlegungshaeuschen.svg");